<script type="text/ecmascript-6">
export default {
    props: ['price', 'merchant', 'type', 'bonus', 'paymentType'],
    computed: {
        getBrandName() {

            return this.bonus.name.toUpperCase()
        },
        getBonusAmount() {

            let value = this.bonus.bonus_amount / 100
            if (this.bonus.bonus_amount_type == 'percent') {

                return value + ' %'
            }

            return '+' + value + ' €'
        },
        isPreco() {

            return this.bonus & this.bonus.can_renew_preauth
        },
        displayBonusAmount() {

            return this.bonus && this.bonus.bonus_amount > 0
        },
    },
}
</script>
<template>
    <div v-if="bonus" class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4 text-success">
            <div class="col-8">
                <span v-if="paymentType == 'coupon'">
                    <strong>{{merchant.coupon_name | capitalize}}</strong><br/>
                </span>
                <span v-if="paymentType == 'cash'"><strong>En cash</strong></span>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success">{{price | currency}}*</h4>
            </div>
        </div>
        <div v-if="price > 0 && paymentType == 'coupon'" class="offset-1 justify-content-end mt-2">
            <p class="text-gd-faint font-size-xxs">
                En {{merchant.coupon_name}} {{merchant.coupon_platform_name}}
                valable <strong>{{merchant.coupon_validity_period}}
                <span v-if="isPreco">pour une {{ bonus.name }}</span> 
                <span v-else>pour l'achat de produits {{ bonus.brand.name }}</span> <span v-html="merchant.coupon_validity_places"></span></strong>.
            </p>
        </div>
        <div v-else-if="price > 0 && paymentType == 'cash'" class="offset-1 justify-content-end mt-2">
            <p class="text-gd-faint font-size-xxs">Sous réserve de conformité de votre produit avec votre déclaratif. Une pièce d'identité et un IBAN valides devront être fournis. Argent disponible sous 14 jours après validation.</p>
        </div>
        <hr class="card-separator"/>
        <div v-if="displayBonusAmount" class="row mt-4 text-success">
            <div class="col-8">
                <span>
                    <strong>Bonus {{getBrandName()}}</strong><br/>
                </span>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success">{{getBonusAmount()}}*</h4>
            </div>
        </div>
        <div v-if="displayBonusAmount" class="offset-1 justify-content-end mt-2">
            <p class="text-gd-faint font-size-xxs">
                <strong v-if="!bonus.is_cash_refund">Sous la forme de code de réduction {{merchant.coupon_platform_name}}</strong>
                <strong v-html="bonus.legal_application_label"></strong>
            </p>
        </div>
        <div v-if="isPreco">
            <p class="mt-4 text-gd-faint font-size-xxs">Bénéficiez de <strong>15 jours de délai supplémentaire</strong> pour expédier votre produit revendu pour une {{ bonus.name }}</p>
        </div>
    </div>
    <div v-else class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4">
            <div class="col-8">
                <span v-if="paymentType == 'coupon'">
                    <strong>{{merchant.coupon_name | capitalize}}</strong>
                </span>
                <span v-if="paymentType == 'cash'"><strong>En cash</strong></span>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success">{{price | currency}}*</h4>
            </div>
        </div>
        <div class="offset-1 justify-content-end mt-2">
            <p v-if="paymentType == 'coupon'" class="text-gd-faint font-size-xxs">
                En {{merchant.coupon_name}} {{merchant.coupon_platform_name}} valable <strong>{{merchant.coupon_validity_period}}</strong> sur <strong>{{merchant.coupon_validity_products}}</strong> <span v-html="merchant.coupon_validity_places"></span>
            </p>
            <p  v-if="paymentType == 'cash'" class="text-gd-faint font-size-xxs">
                Sous réserve de conformité de votre produit avec votre déclaratif. Une pièce d'identité et un IBAN valides devront être fournis. Argent disponible sous 14 jours après validation.
            </p>
        </div>
    </div>
</template>
