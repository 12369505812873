<script type="text/ecmascript-6">

import VouchCash from '@/components/basket/VouchCash.vue'
import VouchBonus from '@/components/basket/VouchBonus.vue'
import Vouch from '@/components/basket/Vouch.vue'

export default {
    components: {Vouch, VouchBonus, VouchCash},
    props: {
        'merchant': {
            'type': Object,
            'required': true
        },
        'bonus': {
            'type': Array,
            'required': true
        },
        'offer': {
            'type': Object,
            'required': true
        },
    },
    data() {

        return {

            colors: ['success', 'info', 'danger'],
            colorIndex: 0
        }
    },
    methods: {
        vouchSelected(selectedVouch) {

            this.$emit('vouchSelected', selectedVouch)
        },
        updateCampaign(campaign) {

            this.$emit('updateCampaign', campaign)
        },
        isDefaultSelected(type) {

            if (type == 'coupon' && this.hasCoupon && !this.hasBonus) return true;

            if (this.hasCoupon) return false;

            if (type == 'cash' && this.hasCash) return true;

            return false;
        },
        incrementColorIndex() {

            this.colorIndex = this.colorIndex + 1

            if (this.colorIndex >= this.colors.length) this.colorIndex = 0
        }
    },
    computed: {
        hasCash() {

            if (this.offer == undefined) return false

            if (this.offer.price <= 0) return false

            if (this.merchant == undefined) return false

            if (this.merchant.has_cash == false) return false

            return true
        },
        hasCoupon() {

            if (this.offer == undefined) return false

            if (this.offer.price <= 0) return false

            if (this.merchant == undefined) return false

            if (this.merchant.coupon_type == undefined) return false

            return true
        },
        hasBonus() {

            if (this.bonus == undefined) return false

            if (this.bonus.length == 0) return false

            return true;
        },
        cashPrice() {

            if (this.offer.price_raw != undefined) return this.offer.price_raw

            return this.offer.price
        },
        sortedBonus() {

            let bonus = this.bonus.toSorted((a, b) => {
                
                if (a.can_renew_preauth == true) return -1
                if (b.can_renew_preauth == true) return 1

                if (a.public_id == 'brand_campaign_FNACAPPLEIPHONE162') return -1
                if (b.public_id == 'brand_campaign_FNACAPPLEIPHONE162') return 1
                
                return Date.parse(b.start_date) - Date.parse(a.start_date) 
            })

            return bonus.map(bonus => {
                
                this.incrementColorIndex()

                bonus.color = this.colors[this.colorIndex]
                
                return bonus
            })
        },
        cashBonuses() {

            return this.bonus.filter(bonus => bonus.is_cash_refund)
        }
    }
}
</script>

<template>
    <div>
        <vouch class="pointer" v-if="hasCoupon" @vouchSelected="vouchSelected"
            :maxBestOffer="offer" 
            :merchantData="merchant"
            :hasBonus="hasBonus"
            :isSelected="isDefaultSelected('coupon')"
        />
        <div class="pointer" v-if="hasCoupon">
            <vouch-bonus v-for="campaign in sortedBonus" :key="campaign.public_id" @vouchSelected="vouchSelected"
                :maxBestOffer="offer"
                :bonus="campaign" 
            />
        </div>  
        <vouch-cash class="pointer" v-if="hasCash" @vouchSelected="vouchSelected"
            :price="cashPrice"
            :isSelected="isDefaultSelected('cash')"
            :bonuses="cashBonuses" 
        />
        
    </div>
</template>


<style scoped>

.pointer {
    cursor: pointer;
}

</style>